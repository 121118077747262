<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<form
		v-else
		class="main-wrapper mt-4"
		@submit.prevent="handleSubmit"
	>
		<CRow class="mb-5">
			<CCol
				v-if="isEditMode"
				md="12"
				class="d-flex justify-content-between align-items-center mb-4"
			>
				<div class="marketing-category-id">
					<span class="typo-caption-2 mr-1">Promotion banner set ID:</span>
					<span class="typo-caption-2 font-weight-bold">
						{{ $route.params.id }}
					</span>
				</div>
			</CCol>
			<CCol md="12">
				<CCol
					col="12"
					class="mb-4 p-0"
				>
					<h4>
						General info
					</h4>
				</CCol>
				<CCol col="12 p-0">
					<CInput
						v-model="$v.formData.name.$model"
						:is-valid="!$v.formData.name.$error && null"
						:invalid-feedback="$t('global.error.required')"
						label="Name*"
					/>
				</CCol>
				<CCol
					xl="12"
					class="d-flex align-items-center my-4 p-0"
				>
					<label class="toggle-label mb-0" for="is-acrive-status">
						Status
					</label>
					<CSwitch
						id="is-acrive-status"
						:checked="formData.isActive"
						variant="3d"
						size="sm"
						class="switch-custom toggle-status mx-3"
						color="success"
						@update:checked="handleUpdateStatus"
					/>
					<span>{{ getIsActiveStatusText }}</span>
				</CCol>
			</CCol>
			<CCol md="12">
				<div class="d-block">
					<label class="mb-0">
						<h3 class="typo-label">Promotion banner*</h3>
						<p class="typo-caption color-black-45">Add promotion banner and drag to reposition. It will be<br> displayed in product detail specification section.</p>
					</label>
					<CButton
						type="button"
						class="btn btn-add float-right"
						color="secondary"
						data-test-id="button-add"
						@click="$refs['modal-add-keys'].open()"
					>
						<CIcon class="icon-left" name="cil-plus" />
						Add promotion banner
					</CButton>
				</div>
			</CCol>
			<CCol lg="12 mt-4">
				<BaseNoItemBanner
					v-if="formData.selectKeys && formData.selectKeys.length === 0"
					:class="{ 'is-invalid': $v.formData.selectKeys.$error }"
					text="There are no promotion banner in this set"
				/>
				<AttributeSetEditKeys
					v-else
					v-model="formData.selectKeys"
					@onChange="handleKeyChange"
				/>
				<div v-if="$v.formData.selectKeys.$error" class="invalid-feedback">
					{{ $t('global.error.required') }}
				</div>
				<hr class="mt-4">
			</CCol>

			<CCol lg="12 mt-4">
				<div class="list-sku">
					<label class="mb-0">
						<h3 class="typo-label">Include SKU*</h3>
						<p class="typo-caption color-black-45">Add SKU product need to show promotion banner.<br> It will be displayed in product detail specification section.</p>
					</label>
					<CButton
						type="button"
						class="btn btn-add float-right"
						color="secondary"
						data-test-id="button-add"
						@click="$refs['modal-sku'].open()"
					>
						{{ `${formData.skuList.length ? 'Edit' : 'Add'} SKU` }}
					</CButton>
				</div>
				<div v-if="!formData.skuList.length" class="mb-3">
					<BaseNoItemBanner
						:class="{ 'is-invalid': $v.formData.skuList.$error }"
						text="There are no SKU"
					/>
					<div v-if="$v.formData.skuList.$error" class="invalid-feedback">
						{{ $t('global.error.required') }}
					</div>
				</div>
				<ul v-else class="list-item show-border">
					<p class="show-text">
						{{ formData.skuList.join('; ') }}
					</p>
				</ul>
			</CCol>
		</CRow>

		<BaseActionPanelStickyFooter
			:disabled-confirm="isSubmitting"
			content-class="main-wrapper"
			:is-edit="isEditMode"
			:remove-text="canRemove"
			@onConfirm="handleSubmit"
			@onCancel="$router.push({ name: ROUTE_NAME.PROMOTION_BANNER_SET_LIST})"
			@onRemove="$refs['modal-remove'].open()"
		/>

		<!-- MODAL SECTION -->
		<AttributeSetEditModalKeys
			ref="modal-add-keys"
			modal-title="Add Promotion banner key"
			:selected-keys="formData.selectKeys"
			:attribute-keys="promotionBannerKeys.data"
			@onAdd="handleAddKeys"
		/>
		<ModalRemoveAttribute
			ref="modal-remove"
			:handle-remove="deletePromotionBannerSet.bind(null, $route.params.id)"
			title="Remove this product banner set?"
			description="This will remove the set, its product banner and all its values from all products currently using this set."
			@onSuccess="$router.replace({ name: ROUTE_NAME.PROMOTION_BANNER_SET_LIST })"
		/>
		<ModalSkuList
			ref="modal-sku"
			:sku-list="formData.skuList"
			title="Add product by SKU"
			title-confirm="Confirm to add product?"
			description-confirm="Confirm to add product?"
			need-confirmation
			@onConfirm="handleConfirmSKU"
		/>
	</form>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';

import AttributeSetEditKeys from '@/components/AttributeSetEditKeys.vue';
import AttributeSetEditModalKeys from '@/components/AttributeSetEditModalKeys.vue';
import ModalRemoveAttribute from '@/components/ModalRemoveAttribute.vue';
import ModalSkuList from '@/components/ModalSkuList.vue';

import { ROUTE_NAME } from '../enums/route';
import { STATUS } from '../mapping/banner';
import { PROMOTION_BANNER_SET_TYPE } from '../enums/promotionBannerSetType';

export default {
	name: 'PromotionBannerSetsForm',

	validations: {
		formData: {
			name: {
				required,
			},
			selectKeys: {
				required,
			},
			skuList: {
				required,
			},
		},
	},

	components: {
		AttributeSetEditKeys,
		AttributeSetEditModalKeys,
		ModalRemoveAttribute,
		ModalSkuList,
	},

	props: {
		defaultData: {
			type: Object,
			default: null,
		},
		isEditMode: {
			type: Boolean,
			default: false,
		},
		isSubmitting: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		const {
			name,
			isActive = false,
			promotionBannerKeys: selectKeys = [],
		} = this.defaultData || {};

		const type = PROMOTION_BANNER_SET_TYPE.SKU;
		const skus = this.defaultData?.skus || [];

		return {
			formData: {
				name,
				selectKeys,
				skuList: skus,
				isActive,
			},
			type,
			ROUTE_NAME,
			id: this.$route.params.id,
			isLoading: false,
			PROMOTION_BANNER_SET_TYPE,
			STATUS,
		};
	},
	computed: {
		...mapState('promotionBannerSets', {
			edit: 'edit',
		}),
		...mapState('promotionBannerKeys', {
			promotionBannerKeys: 'list',
		}),
		...mapGetters({
			promotionBannerSetInfo: 'promotionBannerSets/promotionBannerSetInfo',
		}),

		getItemList() {
			return this.formData.skuList;
		},

		canRemove() {
			return this.isEditMode ? 'Remove promotion banner set' : '';
		},
		getIsActiveStatusText() {
			return this.$t(this.STATUS[this.formData.isActive]);
		},
	},
	async created() {
		this.isLoading = true;
		await this.getPromotionBannerKeys({ per_page: 'all' });
		this.isLoading = false;
	},
	methods: {
		...mapActions({
			getPromotionBannerKeys: 'promotionBannerKeys/getPromotionBannerKeys',
			updatePromotionBannerSet: 'promotionBannerSets/updatePromotionBannerSet',
			deletePromotionBannerSet: 'promotionBannerSets/deletePromotionBannerSet',
			showToast: 'toast/showToast',
		}),
		handleKeyChange(keys) {
			this.formData.selectKeys = keys;
		},
		handleAddKeys(keyIds = []) {
			const newKeys = this.promotionBannerKeys.data.filter((key) => keyIds.some((id) => id === key.id));
			this.formData.selectKeys = [...newKeys, ...this.formData.selectKeys];
		},
		handleSubmit() {
			this.$v.$touch();

			if (this.$v.$invalid) {
				return;
			}

			const params = {
				name: this.formData.name,
				is_active: this.formData.isActive,
				promotion_banner_key_ids: this.formData.selectKeys.map((key) => key.id),
				item_type: this.type,
				items: this.getItemList,
			};

			this.$emit('onSubmit', params);
		},
		handleAddProduct() {
			this.$refs['modal-sku'].open();
		},
		handleConfirmSKU(skus) {
			this.formData.skuList = skus;
		},
		handleUpdateStatus(value) {
			this.formData.isActive = value;
		},
	},
};
</script>

<style lang="scss" scoped>
.empty-key {
	@include typo-subtitle;

	display: flex;
	align-items: center;
	justify-content: center;
	height: rem(138);
	color: $color-black-45;
	background-color: $color-gray-100;
}

.main-wrapper {
	margin-bottom: rem(48);
}

.list-sku {
	display: block;
	margin-bottom: rem(16);
}

.list-item {
	padding: rem(20) rem(16) 0;
	max-height: rem(320);
	overflow: auto;
}

.show-text {
	font-size: rem($font-size-caption);
	font-weight: $font-weight-semi-bold;
	color: $color-black-45;
}

.show-border {
	border: solid 1px $color-gray-400;
	border-radius: rem(4);
}
</style>
